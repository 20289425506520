.b-transport-module {
    // adds proper spacing between elements without 
    // having to set it explicitly on each one
    & > * + * {
    //   margin-top: 1em;
    }

    a{
        text-decoration: none;
        color: #000000;

        &:hover{
            .b-transport-module__list-item-heading{
                text-decoration-color: transparent;
            }
        }
    }
  

    .l-mt-1.t-md-right.col-lg-12{
        display: flex;
        justify-content: center;
    }

    &__heading {
      .b-icon {
        max-width: 1.5rem;
        max-height: 1.5rem;
        margin-right: 0.5rem;
        color: #000000;
        height: 24px;
      }
    }
  
    &__lead {
        padding: 0 1rem;
    }
  
    &__list {
      list-style-type: none;
      padding-inline-start: 0;
      margin: 0;
        li.col-12{
            a{
                padding-left: 1rem!important;
                padding-right: 1rem!important;
            }
        }
    }
  
    &__list-item {
      padding: 1rem;
      &-content {
        box-shadow: none;
        display: block;
        width: 100%;
        & > * + * {
          margin-top: 0.5em;
        }
      }
      &-img {
        $imageHeight: 15rem;
  
        width: 100%;
        height: clamp(12rem, 40vw, 12rem);
        object-fit: cover;
      }
      &-heading {
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: var(--color-purple);
        text-underline-offset: 5px;
        color: var(--color-purple);
        font-size: 22px;
        line-height: 1.3;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        transition: all 200ms;
      }
      &-text {
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }

    .b-page-meta__link{
        border: 2px solid;
        text-decoration: none;
        padding: 0.5em 1.5em;
        display: inline-block;
        margin-top: 1rem;
        margin-right: 1rem;
        min-width: 180px;
        text-align: center;
        font-size: 18px;
        border-radius: 2px;
        color: var(--color-orange) !important;
        &:hover{
            opacity: .85;
        }
    }

    &--bg {
        &-blue-light {
            background-color: #edf8fe;
        }
        
        &-purple-light {
            background-color: #e7e3f1;
        }
        
        &-grey-light {
            background-color: #e9e9e9;
        }
    }
}

.b-transport-module{
    margin-bottom: 60px;
    
    .row {
        // margin-inline: -15px;
    }

    h2{
        font-size: 24px;
        font-weight: bold;
        line-height: 1.4em;
        margin-bottom: 10px;
        padding: 0 1rem;
    }
    .b-box{
        position: relative;
        // padding-bottom: 30px;

        @media ( max-width: 1025px ) {
            margin-bottom: 30px;
        }

        // &:after{
        //     content: '';
        //     width: calc(100% - 20px);
        //     height: 1px;
        //     position: absolute;
        //     bottom: 0;
        //     left: 10px;
        //     background: var(--color-gray-dark);
        //     @media ( max-width: 1025px ) {
        //         left: 15px;
        //         width: calc(100% - 30px);
        //     }
        // }

        &.col-md-3.col-6{
            &:after{
                display: none;
            }
        }

        &:hover{
            .b-box__text-container{
                .b-box__heading{
                    border-bottom: 1px solid transparent;
                }
            }
        }
        
        .b-transport-module__image{
            margin-bottom: 20px;
        }
        .b-box__text-container{
            a{
                text-decoration: none;
            }
            .b-box__heading{
                border-bottom: 1px solid var(--color-purple);
                color: var(--color-purple);
                display: inline;
                font-size: 26px;
                line-height: 35px;
                margin: 20px 0 0;
                -webkit-transition: border-bottom .3s;
                transition: border-bottom .3s;
                @media ( max-width: 1025px ) {
                    font-size: 21px;
                }
            }
        }
    }
    .link-box{
        a{
            color: #000000!important;
        }
    }
}



