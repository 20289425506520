.article-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-inline: -15px;
    @media ( max-width: 1023px ) {
        margin-inline: -7.5px;
    }
    @media ( max-width: 768px ) {
        margin: 0;
    }    

    .article-item {
        padding-inline: 15px;
        width: 33.3333%;
        margin-bottom: 30px;
        align-self: stretch;

        @media ( max-width: 1024px ) {
            width: 50%;
            padding: 0 7.5px;
        }

        @media ( max-width: 768px ) {
            width: 100%;
            padding: 0;
        }    
        
        .article-inner {
            position: relative;
            height: 100%;
        }
        
        .article-image {
            margin-bottom: 15px;
        }

        .img.aspectRatio--340x220 {
            @media ( max-width: 768px ) {
                padding-top: 0;
                height: 220px;
                
                img {
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: initial;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        h2 {
            font-size: var(--teft-typography-l);
            margin-bottom: 25px;
            line-height: 35px;

            a{
                border-bottom: 1px solid var(--color-purple);
                color: var(--color-purple);
                text-decoration: none;
                -webkit-transition: all .2s;
                transition: all .2s; 
                &:hover{
                    border-color: transparent;
                }
            }  
        }
        // width: 33.33%;
        // padding: 0 15px;
        // margin-bottom: 30px;

        // .article-image{
        //     margin-bottom: 20px;
        // }
    }
}

.category-list {
    margin-bottom: 160px;
    
    .category-list-inner {
        padding: 0;
        position: absolute;
        z-index: 1;
        width: calc(100% - (3rem * 2));

        @media ( max-width: 583px ) {
            width: calc(100% - (1rem * 2));
        }
        @media ( max-width: 767px ) {
            width: calc(100% - (1.5rem * 2));
        }
    }
    
    .current-category {
        margin: 0 auto;
        padding: 10px 20px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        line-height: 1.8rem;
        text-align: left;
        border: none;
        cursor: pointer;
        background-color: var(--color-white);

    }

    :not(.active) .current-category::after {
        color: var(--color-gray-dark);
        content: "\25BE";
        display: block;
        // height: 18px;
        top: -2px;
        position: relative;
        transition: transform 0.2s;
    }

    .active .current-category::after {
        transition: transform 0.2s;
        transform: rotate(180deg);
        top: 2px;
    }

    .category-items-container {
        border: 2px solid var(--color-orange);
        margin: 0 auto;
        width: 264px;
    }

    .category-items {
        display: none;
        background-color: var(--color-white);

        a {
            color: var(--color-turquoise-dark);
            padding: 10px 20px;
            line-height: 1.8rem;
            text-decoration: none;
            display: block;
        }

        a:hover {
            text-decoration: underline;
        }
    }
    
    .show {
        display:block;
    }

}

/* -- PAGINATION -- */

.pagination-container {
	display: flex;
	justify-content: center;
	margin: 40px 0 50px;


    .pagemobile {
        display: none !important;
    }
    
    @media (max-width: 1024px) {
        .normalpage {
            display: none !important;
        }
    
        .pagemobile {
            display: inline !important;
        }
    }
}

.pagination-container a{
	border: 1px solid var(--color-purple);
	color: var(--color-purple);
	display: inline-block;
	font-size: 14px;
	line-height: 18px;
	padding: 9px 14px 11px;
	text-decoration: none;
}

.pagination-container a.first {
	border-radius: 3px;
	margin-right: 6px;
}

.pagination-container a.last {
	border-radius: 3px;
	margin-left: 6px;
}

.pagination-container a.page-numbers {
	margin-left: -1px;
}

.pagination-container .page-numbers.dots{
    display: inline-block;
    padding: 0 8px;
}

.pagination-container a.prev {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.pagination-container a.next {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.pagination-container .current {
	background-color: var(--color-purple);
	border: 1px solid var(--color-purple);
	color: var(--color-white);
	display: inline-block;
	font-size: 14px;
	line-height: 18px;
	padding: 9px 14px 11px;
	text-decoration: none;
}

// .pagination-container span:nth-last-child(2) {
// 	border-bottom-right-radius: 3px !important;
// 	border-top-right-radius: 3px !important;
// }

// .pagination-container span:nth-child(2) {
// 	border-bottom-left-radius: 3px !important;
// 	border-top-left-radius: 3px !important;
// }

.search-results-list .pagination-container span {
	font-size: 14px;
	font-style: normal;
	line-height: 18px;
}

@media ( min-width: 201px ) {
	.pagination-container {
		flex-wrap: wrap;
		margin: 40px 0 50px;
	}

	.pagination-container a,
	.pagination-container a:visited {
		margin-bottom: 15px;
	}

	.pagination-container .current {
		margin-bottom: 15px;
	}
}

@media ( min-width: 1024px ) {
	.pagination-container {
		flex-wrap: nowrap;
		margin: 40px 0 50px;
	}

	.pagination-container a,
	.pagination-container a:visited {
		margin-bottom: 0;
	}

	.pagination-container .current {
		margin-bottom: 0;
	}
}

/* -- PAGINATION -- */
