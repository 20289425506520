.site-header {
    padding-inline: 3rem;
    
    @media ( max-width: 583px ) {
        padding-inline: 1rem;
    }
    @media ( max-width: 767px ) {
        padding-inline: 1.5rem;
    }

    .col-lg-12 {
        padding: 0 !important;
    }

    .site-container{
        width: 100%;
        max-width: 100%;
    }
}
