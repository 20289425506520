.query{
    margin-bottom: 50px;

    .input-search__inputs {
        height: 50px;
        display: flex;
        flex-direction: column;
        position: relative;
        background: white;
        border-radius: 4px;
        border: 1px solid #222222;
        margin-bottom: 30px;
    }

    .input-search__field {
        position: absolute;
        z-index: 2;
        background-color: transparent;
        width: 100%;
        min-width: 0;
        font-size: 1rem;
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        color: #025169;
        padding: 0 1em;
        border: 0;
        text-overflow: ellipsis;
    }

    .input-search__button {
        position: absolute;
        z-index: 3;
        right: 2px;
        top: 2px;
        bottom: 2px;
        font-size: 16px;
        padding: 0 1rem;
        background-color: white;
        border: 0;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
    }

    .tabs__wrapper{
        border-bottom: 1px solid #222222;
        margin-bottom: 15px;
    }

    .search-nav-list {
        a {
            text-decoration: none;
        }
        &__heading {
            border-bottom: 1px solid var(--color-orange);
            padding-bottom: 0.25rem;
            font-weight: bold;
            line-height: 1.6; // this is a reset
            display: flex;
            align-items: center;
        }
        &__items {
            list-style-type: none;
            padding: 0;
            margin: 0;
            &--columns {

            }
        }
        &__item {
            border-bottom: 1px solid var(--color-orange);
            position: relative;
            &:before{
                content: "";
                width: 13px;
                height: 13px;
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0NHB4IiB2aWV3Qm94PSIwIDAgNDMgNDQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5hcnJvd19yaWdodF9oZGlyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93X3JpZ2h0X2hkaXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMTM2MDAwLCAwLjYzMjAwMCkiIGZpbGw9IiMwNjY3QzYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMy43NDQsMCBDMjYuNzMwNjgxNiw0LjkwNjY5MTIgMjkuNjg1MzE4Nyw4Ljk3MDY1MDU2IDMyLjYwOCwxMi4xOTIgQzM1LjUzMDY4MTMsMTUuNDEzMzQ5NCAzOC44NjkzMTQ2LDE4LjM2Nzk4NjYgNDIuNjI0LDIxLjA1NiBMNDIuNjI0LDIxLjQ0IEMzOC44NjkzMTQ2LDI0LjEyODAxMzQgMzUuNTMwNjgxMywyNy4wODI2NTA2IDMyLjYwOCwzMC4zMDQgQzI5LjY4NTMxODcsMzMuNTI1MzQ5NCAyNi43MzA2ODE2LDM3LjU4OTMwODggMjMuNzQ0LDQyLjQ5NiBMMTkuNzEyLDM5LjE2OCBDMjQuNTMzMzU3NCwzMS44NzE5NjM1IDI5LjIyNjY0MzgsMjYuNTgxMzQ5OCAzMy43OTIsMjMuMjk2IEMyOS45NTE5ODA4LDIzLjYzNzMzNSAyNi4yNjEzNTEsMjMuODA4IDIyLjcyLDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDE4LjY4OCBMMjIuNzIsMTguNjg4IEMyNi4yNjEzNTEsMTguNjg4IDI5Ljk1MTk4MDgsMTguODU4NjY1IDMzLjc5MiwxOS4yIEMyOS4yMjY2NDM4LDE1LjkxNDY1MDIgMjQuNTMzMzU3NCwxMC42MjQwMzY1IDE5LjcxMiwzLjMyOCBMMjMuNzQ0LDAgWiIgaWQ9IuKGkiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 17px;
                right: 20px;
                filter: brightness(0);
            }

            @for $i from 1 through 100 {
                &:nth-child(#{$i}) {
                    .b-nav-list__link--ordered {
                    &:before {
                        display: block;
                        content: '#{$i}';
                        padding-right: 1em;
                        color: black;
                    }
                    }
                }
            }
        }

        &__item-title{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 992px) {
                align-items: flex-start; 
            }

            .date-circle{
                margin-right: 1rem;
                background: #ffe2c4;
                border-radius: 50%;
                height: 66px;
                width: 66px;
                font-size: 14px;
                line-height: 1.2;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex-wrap: nowrap;
                flex-shrink: 0;
                font-weight: 400;
                .big{
                    font-size: 20px;
                }
            }
        }

        &__item-intro {
            font-weight: 400;
            margin-top: 10px;
        }
        
        &__item-info {
            font-size: 0.8725rem;
            padding-right: 1.5rem;
            flex-shrink: 0;
            

            &--file {
                position: relative;
                color: black;
                padding-right: 1rem;
                padding-left: 0;
                height: 1.4em;
                cursor: pointer;
            }
        }
        &__link {
            display: flex;
            justify-content: space-between;
            text-align: left;
            position: relative;
            cursor: pointer;
            padding: 10px 48px 10px 15px;
            line-height: 1.4;
            
            &[href]{
                color: #222222;
                font-weight: 700;
            }

            &:hover {
                &[href] {
                    // color: var(--color-orange);
                    background: rgba(240,110,36,.06);
                }
            }
        
            &--active {
                cursor: default;
                background-color: rgba(
                    #ffc900,
                    0.15
                ) !important; // We use important here, because we don't have the hover state to override it.
                transition: background-color 0.3s;
            
                &:hover {
                    color: black !important;
                }
            }
        
            &--no-arrow {
                padding-right: 1rem !important;
                &:before {
                    display: none !important;
                }
                &:after {
                    display: none !important;
                }
            }
        }

        &__button{
            margin-top: 20px;
            display: inline-block;
            border: 2px solid var(--color-orange);
            color: var(--color-orange);
            background: transparent;
            padding:  6px 30px;
            border-radius: 2em;
            font-size: 16px;
            white-space: nowrap;
            cursor: pointer;
            transition: all .1s ease-out;

            &:hover{
                color: #ffffff;
                background: var(--color-orange); 
            }
        }
    }
        
    #query-loader {
        display: none;
    }
        
    /* Create the spinning loader using the :before and :after pseudo-elements */
    #query-loader {
        width: 40px;
        height: 40px;
        margin: 20px auto;
        background-color: #333;
        border-radius: 100%;
        position: relative;
        -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
        animation: sk-scaleout 1.0s infinite ease-in-out;
        }
        
        #query-loader:before {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        border-radius: 100%;
        border: 5px solid transparent;
        border-top-color: #fff;
        -webkit-animation: sk-spin 2.0s infinite linear;
        animation: sk-spin 2.0s infinite linear;
    }
        
    #query-loader:after {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        border-radius: 100%;
        border: 5px solid transparent;
        border-top-color: #fff;
        -webkit-animation: sk-spin 5.0s infinite linear;
        animation: sk-spin 5.0s infinite linear;
        }
        
        @-webkit-keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
        }
        100% {
            -webkit-transform: scale(1.0);
            opacity: 0;
        }
        }
        
        @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        100% {
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
            opacity: 0;
        }
        }
        
        @-webkit-keyframes sk-spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
        }
        
        @keyframes sk-spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .tab-btn {
        display: inline-block;
        padding: 10px 15px;
        border-radius: 4px 4px 0 0;
        border: none;
        background-color: #fff;
        cursor: pointer;
        margin-bottom: -1px;
        font-size: 17px;
        line-height: 26px;

        span{
            display: inline-block;
            border-radius: 50%;
            min-width: 26px;
            height: 26px;
            margin-left: 0.5rem;
            text-align: center;
            background: #ffe2c4;
            padding: 0 4px;
        }
    }

    .active-tab {
        background-color: #fff;
        border: 1px solid #222222;
        border-bottom: 1px solid #fff;
    }

    .tab-content {
        display: none;
    }

    .active-content {
        display: block;
    }

    .bottom-link {
        margin-top: 15px;
        text-align: right;
        a {
            text-decoration: none;
            color: black;
    
            margin-top: 15px;
    
            &::after{
                content: "";
                width: 13px;
                height: 13px;
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0NHB4IiB2aWV3Qm94PSIwIDAgNDMgNDQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5hcnJvd19yaWdodF9oZGlyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93X3JpZ2h0X2hkaXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMTM2MDAwLCAwLjYzMjAwMCkiIGZpbGw9IiMwNjY3QzYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMy43NDQsMCBDMjYuNzMwNjgxNiw0LjkwNjY5MTIgMjkuNjg1MzE4Nyw4Ljk3MDY1MDU2IDMyLjYwOCwxMi4xOTIgQzM1LjUzMDY4MTMsMTUuNDEzMzQ5NCAzOC44NjkzMTQ2LDE4LjM2Nzk4NjYgNDIuNjI0LDIxLjA1NiBMNDIuNjI0LDIxLjQ0IEMzOC44NjkzMTQ2LDI0LjEyODAxMzQgMzUuNTMwNjgxMywyNy4wODI2NTA2IDMyLjYwOCwzMC4zMDQgQzI5LjY4NTMxODcsMzMuNTI1MzQ5NCAyNi43MzA2ODE2LDM3LjU4OTMwODggMjMuNzQ0LDQyLjQ5NiBMMTkuNzEyLDM5LjE2OCBDMjQuNTMzMzU3NCwzMS44NzE5NjM1IDI5LjIyNjY0MzgsMjYuNTgxMzQ5OCAzMy43OTIsMjMuMjk2IEMyOS45NTE5ODA4LDIzLjYzNzMzNSAyNi4yNjEzNTEsMjMuODA4IDIyLjcyLDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDE4LjY4OCBMMjIuNzIsMTguNjg4IEMyNi4yNjEzNTEsMTguNjg4IDI5Ljk1MTk4MDgsMTguODU4NjY1IDMzLjc5MiwxOS4yIEMyOS4yMjY2NDM4LDE1LjkxNDY1MDIgMjQuNTMzMzU3NCwxMC42MjQwMzY1IDE5LjcxMiwzLjMyOCBMMjMuNzQ0LDAgWiIgaWQ9IuKGkiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                filter: brightness(0);
                margin-left: 0.4em;
            }
        }
        
    }

}
