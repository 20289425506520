:root {

	/**
	 * Font-weights
	 */

	--avn-weight-bold: 700;
	--avn-weight-light: 300;
	--avn-weight-regular: 400;

	/**
	 * Local theme colors
	 */
	--color-black: black;
	--color-gray: #e8e8e8;
	--color-gray-dark: #979797;
	--color-gray-light: #f3f3f3;
	--color-orange: #f06e24;
	--color-purple: #65428a;
	--color-turquoise: #49a29d;
	--color-turquoise-light: #a3ebe8;
	--color-turquoise-dark: #0f7c76;
	--color-white: white;
	--color-orange-light:#ffe2c4;
	--color-green-light: #d4f1d4;
	--color-red-light: #f3bebe;

	/**
	 * Forms
	 */

	--teft-form-border-radius: 3px;
	--teft-form-input-height: 48px;
	--teft-form-input-padding: 35px;

	/**
	 * Theme settings
	 */

	--teft-spacing-block-margin: 40px;
	--teft-theme-alert-color: var(--color-orange);
	--teft-theme-body-font-color: var(--color-black);
	--teft-theme-content-width: 35.5rem; // 710px
	--teft-theme-primary-color: var(--color-turquoise);
	--teft-theme-secondary-color: var(--color-white);
	--teft-theme-site-width: 54rem; // 1080px
  --teft-theme-gap: 1em;

	/**
	 * Typography
	 */

	--teft-typeograhy-heading-font-weight: 700;
	// --teft-typography-font-family: "Calibri", "Lucida Grande", arial, sans-serif;
	--teft-typography-l: 1.3em; /* 26px */
	--teft-typography-m: 1em; /* 20px */
	--teft-typography-s: 0.9em; /* 18px */
	--teft-typography-sm: 0.8em; /* 16px */
	--teft-typography-xl: 1.6em; /* 32px */
	--teft-typography-xxl: 2em; /* 40px */
	--teft-typography-xxxl: 2.75em; /* 55px */
}

@media ( min-width: 1025px ) {
	:root {
		--teft-theme-gap: 1.5em;
		--teft-typography-base: 1.25em;
		--teft-typography-body-line-height: 1.4em; /* 28px */
	}
}

// Backgrounds
$color__background-body: #fff;
$color__background-input: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #0073aa;
$color__background-button-hover: #111;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;
$color__background_selection: mix( $color__background-body, $color__background-button, 75% ); // lighten( salmon, 22.5% ); // lighten( #0999d4, 48% );

// Text
$color__text-main: #111;
$color__text-light: #767676;
$color__text-hover: lighten( #111, 22.5% );
$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;

// Links
$color__link: #0073aa;
$color__link-visited: #0073aa;
$color__link-hover: darken( $color__link, 10% );

// Borders
$color__border: #ccc;
$color__border-link: #0073aa;
$color__border-link-hover: darken( $color__link, 10% );
$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: $color__border;
$color__border-abbr: #666;

// Responsive widths.

$size__spacing-unit: 1rem;
$size__site-main: 100%;
$size__site-sidebar: 25%;
$size__site-margins: calc(10% + 60px);
$size__site-tablet-content: calc(8 * (100vw / 12) - 28px);
$size__site-desktop-content: calc(6 * (100vw / 12) - 28px);

// Responsive widths.

$mobile_width: 600px;
$tablet_width: 768px;
$desktop_width: 1168px;
$wide_width: 1379px;


@mixin media( $res ) {
	@if mobile == $res {
		@media only screen and (min-width: $mobile_width) {
			@content;
		}
	}

	@if tablet == $res {
		@media only screen and (min-width: $tablet_width) {
			@content;
		}
	}

	@if desktop == $res {
		@media only screen and (min-width: $desktop_width) {
			@content;
		}
	}

	@if wide == $res {
		@media only screen and (min-width: $wide_width) {
			@content;
		}
	}
}

@mixin link-transition( $attr: color ) {
	transition: $attr $link_transition ease-in-out;
}

@mixin button-transition() {
	transition: background $button_transition ease-in-out;
}

@mixin button-all-transition() {
	transition: all $button_transition ease-in-out;
}

@mixin background-transition() {
	transition: background $background_transition ease-in-out;
}

@mixin selection {
	::-moz-selection {
		@content;
	}
	::selection {
		@content;
	}
}

.u-bg-color{
    &--black{ background-color: var(--color-black)!important;}
    &--gray{ background-color: var(--color-gray)!important;}
    &--gray-dark{ background-color: var(--color-gray-dark)!important;}
    &--gray-light{ background-color: var(--color-gray-light)!important;}
    &--orange{ background-color: var(--color-orange)!important;}
    &--purple{ background-color: var(--color-purple)!important;}
    &--turquoise{ background-color: var(--color-turquoise)!important;}
    &--turquoise-light{ background-color: var(--color-turquoise-light)!important;}
    &--turquoise-dark{ background-color: var(--color-turquoise-dark)!important;}
    &--white{ background-color: var(--color-white)!important;}
	&--green-light{ background-color: var(--color-green-light)!important;}
	&--orange-light{ background-color: var(--color-orange-light)!important;}
	&--red-light{ background-color: var(--color-red-light)!important;}
}

.has{
    &-black-color{ color: var(--color-black)!important;}
    &-gray-color{ color: var(--color-gray)!important;}
    &-gray-dark-color{ color: var(--color-gray-dark)!important;}
    &-gray-light-color{ color: var(--color-gray-light)!important;}
    &-orange-color{ color: var(--color-orange)!important;}
    &-purple-color{ color: var(--color-purple)!important;}
    &-turquoise-color{ color: var(--color-turquoise)!important;}
    &-turquoise-light-color{ color: var(--color-turquoise-light)!important;}
    &-turquoise-dark-color{ color: var(--color-turquoise-dark)!important;}
    &-white-color{ color: var(--color-white)!important;}
}

.u-text-color{
    &--black{ color: var(--color-black)!important;}
    &--gray{ color: var(--color-gray)!important;}
    &--gray-dark{ color: var(--color-gray-dark)!important;}
    &--gray-light{ color: var(--color-gray-light)!important;}
    &--orange{ color: var(--color-orange)!important;}
    &--purple{ color: var(--color-purple)!important;}
    &--turquoise{ color: var(--color-turquoise)!important;}
    &--turquoise-light{ color: var(--color-turquoise-light)!important;}
    &--turquoise-dark{ color: var(--color-turquoise-dark)!important;}
    &--white{ color: var(--color-white)!important;}
}

.hidden {
	display: none !important;
}
