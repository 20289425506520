.article,
.arrangement,
.nyhet,
.event{
    max-width: var(--teft-theme-content-width);
    margin: 0 auto 30px auto;

    &__header{
        h1{
            font-size: var(--teft-typography-xxxl);
            font-weight: var(--avn-weight-bold);
            line-height:  1.2;
            margin-block: 22px;
            color: #000000;

            @media ( max-width: 1024px ) {
                margin-block: 36.52px;
            }
        }
    }
    &__top{
        margin-bottom: 30px;
        .info-line{
            display: flex;
            border-bottom: 1px solid var(--color-gray-dark);
            border-top: 1px solid var(--color-gray-dark);
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px 0;
            .author, .posted {
                font-size: var(--teft-typography-sm);
                line-height: 20px;
            }
        }
    }
    &__content{
        .feature-image{
            margin: 0 auto 50px auto;
            figcaption{
                font-size: var(--teft-typography-sm);
                color: #555d66;
                text-align: center;
                margin-top: 10px;
                display: block;
            }
        }
    }

    .event-about{
        margin-top: 40px;
        margin-bottom: 40px;
        h3{
            font-size: var(--teft-typography-xl);
            font-weight: var(--avn-weight-light);
        }
        p {
            margin-top: 10px;
            line-height: 1.3;
            strong {
                font-weight: bolder;
            }
        }

        .info{
            margin-top: 40px;
        }
        .place{
            margin-top: 40px;
        }
        .btn-link{
            margin-top: 15px;
            a{
                color: #ffffff;
                border-radius: 2px;
                border-style: solid;
                border-width: 2px;
                font-size: var(--teft-typography-s);
                line-height: 1.5;
                min-width: 180px;
                text-decoration: none;
                padding: 12px 24px;
                display: inline-block;
                text-align: center;
                overflow-wrap: break-word;
                &:hover{
                    opacity: .7;
                }
            }
        }
        .programs{
            margin-top: 40px;
            p{
                margin-top: 0;
                margin-bottom: 40px;
            }
            h4{
                font-size: var(--teft-typography-l);
                font-weight: var(--avn-weight-bold);
                color: var(--teft-theme-heading-font-color);
                line-height: var(--teft-typography-heading-line-height);
                margin-bottom: 15px;
            }
        }
    }

    h1,h2,h3,h4,h5,h6{
        // max-width: 710px;
        margin: 0 auto; 
    }
    h1{
        color: var(--color-black);
        font-size: 55px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 22px;            
        @media ( max-width: 1025px ) {
            font-size: 44px;
        }        
    }
    h2{
        color: var(--color-black);
        font-size: 40px;
        line-height: 1.2;
        font-weight: 400;
        margin-bottom: 25px;            
        @media ( max-width: 1025px ) {
            font-size: 32px;
        }        
    }
    h3{
        color: var(--color-black);
        font-size: var(--teft-typography-xl);
        font-weight: var(--avn-weight-light);
        margin-bottom: 25px;       
    }
    h4 {
        color: var(--color-black);
        font-size: var(--teft-typography-l);
        font-weight: var(--avn-weight-bold);
        margin-bottom: 25px;
    }
    h5{
        color: var(--color-black);
        font-size: var(--teft-typography-m);
        font-weight: var(--avn-weight-bold); 
        margin-bottom: 40px;
    }
    h6{
        color: var(--color-black);
        font-size: 0.67em;
        font-weight: var(--teft-typography-heading-font-weight);
        margin-bottom: 40px; 
    }

    ul,ol{
        margin: 0px 0 40px 0;
        list-style: initial;
        list-style-position: outside;
        padding-left: 0;
        li{
            margin-bottom: 6px;
            margin-left: 2.5em;
        }
        a{
            font-style: initial;
        }
    }

    ol {
        list-style-type: decimal;
    }

    table {
        margin-block: 32px;
        
        td,th {
            padding: 8px 12px;
            border-right: 1px solid #0004;
            border-bottom: 1px solid #0004;
        }

        tr {
            td:last-child,th:last-child {
                border-right: none;
            }

            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }

    strong {
        font-weight: 600;
    }

    em {
        font-style: italic;
    }
}

.other-content{
    max-width: 710px;
    margin: 40px auto 0 auto;

    .row{
        margin: 0;
    }

}
