.page404 {
    margin-bottom: 70px;

    &__title{
        text-decoration: none;
        
        h1 {
            text-align: center;
            color: var(--color-orange);
            font-size: var(--teft-typography-xxxl);
            font-weight: var(--avn-weight-bold);
        }
        p {
            text-align: center;
            margin: 50px auto 0;
            font-size: auto;
        }
        
    }

        .search-form {
            margin: 50px auto 10px;
        }
}