.c-banner {
  margin-bottom: 50px;
  padding: 35px 0;
}
.only-image {
  padding: 0 !important;
}
.c-banner__lead {
  font-size: 19.2px;

  &.mb-4 {
    line-height: 32px;
  }
}
.c-banner__img {
  width: 100%;
  max-width: 500px;
  margin-right: auto;
}
.c-banner__heading {
  font-size: 24px;
  line-height: 65.5px;
}
.c-banner__figure {
  margin-bottom: 20px;
}
.c-banner__link, .c-banner__link-white {
  font-size: 20px;
  color: inherit;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0;
  border: none;
  border-radius: 0px;
  text-decoration: none;
  border-bottom: 2.6px solid var(--color-orange);
  height: auto;
  min-height: auto;
  display: inline-block;
}

.c-banner__link:after {
  content: "";
  width: 34px;
  height: 21px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0NHB4IiB2aWV3Qm94PSIwIDAgNDMgNDQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5hcnJvd19yaWdodF9oZGlyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93X3JpZ2h0X2hkaXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMTM2MDAwLCAwLjYzMjAwMCkiIGZpbGw9IiMwNjY3QzYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMy43NDQsMCBDMjYuNzMwNjgxNiw0LjkwNjY5MTIgMjkuNjg1MzE4Nyw4Ljk3MDY1MDU2IDMyLjYwOCwxMi4xOTIgQzM1LjUzMDY4MTMsMTUuNDEzMzQ5NCAzOC44NjkzMTQ2LDE4LjM2Nzk4NjYgNDIuNjI0LDIxLjA1NiBMNDIuNjI0LDIxLjQ0IEMzOC44NjkzMTQ2LDI0LjEyODAxMzQgMzUuNTMwNjgxMywyNy4wODI2NTA2IDMyLjYwOCwzMC4zMDQgQzI5LjY4NTMxODcsMzMuNTI1MzQ5NCAyNi43MzA2ODE2LDM3LjU4OTMwODggMjMuNzQ0LDQyLjQ5NiBMMTkuNzEyLDM5LjE2OCBDMjQuNTMzMzU3NCwzMS44NzE5NjM1IDI5LjIyNjY0MzgsMjYuNTgxMzQ5OCAzMy43OTIsMjMuMjk2IEMyOS45NTE5ODA4LDIzLjYzNzMzNSAyNi4yNjEzNTEsMjMuODA4IDIyLjcyLDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDE4LjY4OCBMMjIuNzIsMTguNjg4IEMyNi4yNjEzNTEsMTguNjg4IDI5Ljk1MTk4MDgsMTguODU4NjY1IDMzLjc5MiwxOS4yIEMyOS4yMjY2NDM4LDE1LjkxNDY1MDIgMjQuNTMzMzU3NCwxMC42MjQwMzY1IDE5LjcxMiwzLjMyOCBMMjMuNzQ0LDAgWiIgaWQ9IuKGkiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: 21px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: brightness(0);
  margin-left: 0.5em;
  margin-top: 5px;
  position: absolute;
}

.u-text-color--white .c-banner__link:after {
  content: "";
  width: 34px;
  height: 21px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0NHB4IiB2aWV3Qm94PSIwIDAgNDMgNDQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5hcnJvd19yaWdodF9oZGlyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93X3JpZ2h0X2hkaXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMTM2MDAwLCAwLjYzMjAwMCkiIGZpbGw9IiMwNjY3QzYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMy43NDQsMCBDMjYuNzMwNjgxNiw0LjkwNjY5MTIgMjkuNjg1MzE4Nyw4Ljk3MDY1MDU2IDMyLjYwOCwxMi4xOTIgQzM1LjUzMDY4MTMsMTUuNDEzMzQ5NCAzOC44NjkzMTQ2LDE4LjM2Nzk4NjYgNDIuNjI0LDIxLjA1NiBMNDIuNjI0LDIxLjQ0IEMzOC44NjkzMTQ2LDI0LjEyODAxMzQgMzUuNTMwNjgxMywyNy4wODI2NTA2IDMyLjYwOCwzMC4zMDQgQzI5LjY4NTMxODcsMzMuNTI1MzQ5NCAyNi43MzA2ODE2LDM3LjU4OTMwODggMjMuNzQ0LDQyLjQ5NiBMMTkuNzEyLDM5LjE2OCBDMjQuNTMzMzU3NCwzMS44NzE5NjM1IDI5LjIyNjY0MzgsMjYuNTgxMzQ5OCAzMy43OTIsMjMuMjk2IEMyOS45NTE5ODA4LDIzLjYzNzMzNSAyNi4yNjEzNTEsMjMuODA4IDIyLjcyLDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDIzLjgwOCBMLTEuNDIxMDg1NDdlLTE0LDE4LjY4OCBMMjIuNzIsMTguNjg4IEMyNi4yNjEzNTEsMTguNjg4IDI5Ljk1MTk4MDgsMTguODU4NjY1IDMzLjc5MiwxOS4yIEMyOS4yMjY2NDM4LDE1LjkxNDY1MDIgMjQuNTMzMzU3NCwxMC42MjQwMzY1IDE5LjcxMiwzLjMyOCBMMjMuNzQ0LDAgWiIgaWQ9IuKGkiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: 21px;
  background-repeat: no-repeat;
  display: inline-block;
  filter: brightness(0) saturate(100%) invert(99%) sepia(11%) saturate(178%) hue-rotate(21deg) brightness(119%) contrast(100%);
  margin-left: 0.5em;
  margin-top: 5px;
  position: absolute;
}

@media (min-width: 30rem) {
  .c-banner__heading {
    font-size: 32px;
  }
}
@media (min-width: 40rem) {
  .c-banner__heading {
    font-size: 40px;
  }
}
@media (min-width: 60rem) {
  .c-banner__heading {
    font-size: 48px;
  }
}

@media (min-width: 768px) {
  .c-banner__lead {
    font-size: 22.4px;
  }
  .c-banner__figure {
    margin-bottom: 0px;
  }
}
