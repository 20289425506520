.people-list{
    margin-bottom: 50px;
    &__wrapper{
        // max-width: 710px;
        margin: 0 auto;
    }    
    &__title{
        margin-bottom: 50px;
        color: var(--color-black);
        font-size: 40px;
        line-height: 1.2;
        font-weight: 400;
        @media ( max-width: 1025px ) {
            font-size: 32px;
        }
    }
    &__item{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        @media ( max-width: 768px ) {
            flex-direction: column;
        }
        .image{
            width: 238px;
            @media ( max-width: 768px ) {
                margin-bottom: 30px;
            }
            img{
                max-width: 100%;
            }
        }
        .info{
            width: calc(100% - 138px);
            padding-left: 30px;
            color: var(--teft-theme-body-font-color);

            @media ( max-width: 768px ) {
                width: 100%;
                padding-left: 0;
            }
            .name{
                font-size: var(--teft-typography-xl);
                font-weight: 400;
                margin-bottom: 15px;
            }
            .occupation{
                font-style: italic;
                margin-bottom: 20px;
                display: block;
            }
            .email{
                a{
                    color: var(--color-turquoise-dark);
                }
            }
            .email,
            .phoneNumber{
                margin-bottom: 10px;
                strong{
                    font-weight: 700;
                }
            }
            .lectureName{

            }
        }
    }
}
