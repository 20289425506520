.smallButtonLink{
    a{
        background: #32373c;
        border-radius: 2em;
        color: #fff;
        font-size: 13px;
        line-height: 100%;
        padding: 0.5em 1em;
        text-decoration: none;
        font-style: initial;
    }
}