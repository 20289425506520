.articleList{
    margin-bottom: 50px;
    &__title{
        margin-bottom: 20px;
        color: var(--color-black);
        font-size: 40px;
        line-height: 1.2;
        font-weight: 400;
    }
    &__item{
        margin-bottom: 30px;
        padding-bottom: 30px;
        
        &:after {
            content: '';
            width: calc(100% - 20px);
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 10px;
            background: var(--color-gray-dark);
        }

        &:hover{
            .title h3{
                border-bottom: 1px solid transparent;
            }
        }
        .image{
            margin-bottom: 20px;
            img{
                height: 210px;
                object-fit: cover;
                width: 100%;
                max-width: 100%;
            }
        }
        .title{
            text-decoration: none;
            h3{
                border-bottom: 1px solid var(--color-purple);
                color: var(--color-purple);
                display: inline;
                font-size: 26px;
                line-height: 35px;
                margin: 20px 0 0;
                -webkit-transition: border-bottom .3s;
                transition: border-bottom .3s;
                max-height: none !important;
                overflow-wrap: break-word;
                word-wrap: break-word;
                hyphens: auto;

                @media (max-width: 1025px){
                    font-size: 21px;
                }

            }
            
        }
    }
    &__button{
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            display: inline-block;
            text-decoration: none;
            border-radius: 2px;
            border: 2px solid;
            font-size: 18px;
            line-height: 28px;
            min-width: 180px;
            padding: 9px 27px;
            @media (max-width: 1025px){
                font-size: 15px;
            }
            @media (max-width: 600px){
                padding: 7px 15px;
            }
        }
    }

    &__display-flex{
        display: flex;
        flex-wrap: wrap;
    }
}
