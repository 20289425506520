.emailRegistration{
    margin-bottom: 50px;
    
    &__wrapper{
        background-color: var(--color-turquoise-dark);
        padding: 60px 45px 80px 45px;
    }

    &__content{
        max-width: 710px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        @media ( max-width: 1025px ) {
            flex-direction: column;
        } 
    }

    &__heading{
        width: 50%;
        @media ( max-width: 1025px ) {
            width: 100%;
        } 
        h2{
            margin-bottom: 27px;
            color: var(--color-white);
            font-size: 40px;
            line-height: 1.3;
        }
        p{
            color: var(--color-white);
            font-size: 20px;
            line-height: 1.3;
        }
    }
    &__form{
        width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        @media ( max-width: 1025px ) {
            width: 100%;
            margin-top: 30px;
        } 

        form{
            width: 100%;
            max-width: 306px;

            input[type="email"]{
                display: block;
                width: 100%;
                background-color: var(--color-turquoise-light);
                border: none;
                height: 48px;
                border-radius: 3px;
                margin-bottom: 10px;
                padding: 0 35px;
                font-size: 20px;
                @media ( max-width: 1025px ) {
                    font-size: 16px;
                }                 
            }
            input[type="submit"]{
                font-size: 20px;
                border: none;
                border-radius: 3px;
                min-width: 93px;
                height: 48px;
                @media ( max-width: 1025px ) {
                    font-size: 16px;
                }  
            }
        }

    }
}