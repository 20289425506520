.faktaboks {
  margin-top: 50px;
  background: antiquewhite;
  padding: 1em;

  ul {
    margin: 0;
  }

  &__item {
    list-style-type: none;
    font-size: medium;

    &-title{
      font-weight: bold;
    }
  }

  &__kontakt {
    font-size: medium;
    margin-top: 20px;
    padding-left: 1.5em;

    &-name {
      font-weight: bold;
    }
  }
}
