.tekst-part{
    &__content{
        margin-inline: auto;
        margin-bottom: 85px;

        .u-bg-color--white {
            padding: 15px 45px 15px 45px !important;
        }

        &>div[class*="u-bg-color"] {
            padding: 50px 45px 50px 45px;
        }

        h1,h2,h3,h4,h5,h6{
            // max-width: 710px;
            margin: 0 auto; 
        }
        h1{
            font-size: 55px;
            line-height: 1.2;
            font-weight: 700;
            margin-bottom: 22px;            
            @media ( max-width: 1025px ) {
                font-size: 44px;
            }        
        }
        h2{
            font-size: 40px;
            line-height: 1.2;
            font-weight: 400;
            margin-bottom: 25px;            
            @media ( max-width: 1025px ) {
                font-size: 32px;
            }        
        }
        h3{
            font-size: var(--teft-typography-xl);
            font-weight: var(--avn-weight-light);
            margin-bottom: 25px;       
        }
        h4 {
            font-size: var(--teft-typography-l);
            font-weight: var(--avn-weight-bold);
            margin-bottom: 25px;
        }
        h5{
            color: var(--color-black);
            font-size: var(--teft-typography-m);
            font-weight: var(--avn-weight-bold); 
            margin-bottom: 40px;
        }
        h6{
            color: var(--color-black);
            font-size: 0.67em;
            font-weight: var(--teft-typography-heading-font-weight);
            margin-bottom: 40px; 
        }
    
        p{
            font-size: 20px;
            line-height: 1.4;
            // max-width: 710px;
            margin: 0 auto 40px auto;
            @media ( max-width: 1025px ) {
                font-size: 16px;
                line-height: 1.8;
            }   
            &:last-child{
                margin-bottom: 0;
            }

            strong {
                font-weight: 600;
            }

            em {
                font-style: italic;
            }
        }

        .link-box {
            margin-top: 50px;
            @media ( max-width: 1025px ) {
                margin-top: 40px;
            }  
        }

        table {
            margin-block: 32px;
             
            td,th {
                padding: 8px 12px;
                border-right: 1px solid #0004;
                border-bottom: 1px solid #0004;
            }

            tr {
                td:last-child,th:last-child {
                    border-right: none;
                }

                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }

        .quote {
            padding: 64px 15px;
            font-style: italic;
            
            p:not(.quote-credit) {
                font-size: 24px;
                font-weight: 300;

                &::before, &::after {
                    position: relative;
                    display: inline;
                }
    
                &::before {
                    content: '\00AB';
                }
                &::after {
                    content: '\00BB';
                }

                margin-bottom: 32px;
            }

            cite {
                font-size: 16px;
                font-style: normal;
                color: $color__text-light;
            }
        }
    }

    &__content-text-wrapper{
        max-width: 710px;
        margin: 0 auto;

        figure{
            margin-bottom: 15px;
        }

        .editor-align-right{
            margin-left: 30px;
        }

        a{
            color: var(--color-turquoise-dark);
        }

        ul,ol{
            margin: 0px 0 40px 0;
            list-style: initial;
            list-style-position: outside;
            padding-left: 0;
            li{
                margin-bottom: 6px;
                margin-left: 2.5em;
            }
            a{
                font-style: initial;
            }
        }

        ol {
            list-style-type: decimal;
        }
    }

    .tekst-part__content {
        margin-bottom: 50px;
    }
}


.tekst-image-left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media ( max-width: 768px ) {
        flex-direction: column;
    } 
    
    .tekst-part__content-text-wrapper{
        margin: initial;
    }
    .tekst-images{
        width: 30%;
        padding-block: 0.7rem;

        @media ( max-width: 768px ) {
            width: 100%;
            max-width: 300px;
            margin-bottom: var(--teft-theme-gap);
        }

        @media ( max-width: 599px ) {
            align-self: center;
        }
        
        &+div{
            width: calc(100% - 200px);
            padding-left: var(--teft-theme-gap);
            @media ( max-width: 1025px ) {
                width: calc(100% - 100px);
                padding: 0 0 0 4%;
            }

            @media ( max-width: 768px ) {
                width: 100%;
            }
        }
    }
}

.tekst-image-left-center {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-items: center;


    @media ( max-width: 768px ) {
        flex-direction: column;
    } 
    
    @media ( max-width: 1025px ) {
        align-items: center;
    } 
    .tekst-part__content-text-wrapper{
        margin: initial;
    }
    .tekst-images{
        width: 30%;
        padding-block: 0.7rem;
        align-self: center;

        @media ( max-width: 768px ) {
            width: 100%;
            max-width: 300px;
            margin-bottom: var(--teft-theme-gap);
        }

        @media ( max-width: 599px ) {
            align-self: center;
        }

        &+div{
            width: calc(100% - 200px);
            padding-left: var(--teft-theme-gap);

            @media ( max-width: 1025px ) {
                width: calc(100% - 100px);
                padding: 0 0 0 4%;
            }
            
            @media ( max-width: 768px ) {
                width: 100%;
            }
        }
    }
    .link-box{
        text-align: left;
    }
}

.tekst-image-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;

    @media ( max-width: 768px ) {
        flex-direction: column-reverse;
    } 
    
    .tekst-part__content-text-wrapper{
        margin: initial;
    }
    .tekst-images{
        width: 30%;
        padding-block: 0.7rem;

        @media ( max-width: 768px ) {
            width: 100%;
            max-width: 300px;
            margin-bottom: var(--teft-theme-gap);
        }

        @media ( max-width: 599px ) {
            align-self: center;
        }
        
        &+div{
            width: calc(100% - 200px);
            padding-left: var(--teft-theme-gap);
            @media ( max-width: 1025px ) {
                width: calc(100% - 100px);
                padding: 0 0 0 4%;
            }

            @media ( max-width: 768px ) {
                width: 100%;
            }
        }
    }
}

.tekst-image-right-center {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 auto;

    @media ( max-width: 768px ) {
        flex-direction: column-reverse;
    } 
    
    @media ( max-width: 1025px ) {
        align-items: center;
    } 
    .tekst-part__content-text-wrapper{
        margin: initial;
    }
    .tekst-images{
        width: 30%;
        padding-block: 0.7rem;
        align-self: center;

        @media ( max-width: 768px ) {
            width: 100%;
            max-width: 300px;
            margin-bottom: var(--teft-theme-gap);
        }

        @media ( max-width: 599px ) {
            align-self: center;
        }

        &+div{
            width: calc(100% - 200px);
            padding-left: var(--teft-theme-gap);

            @media ( max-width: 1025px ) {
                width: calc(100% - 100px);
                padding: 0 0 0 4%;
            }
            
            @media ( max-width: 768px ) {
                width: 100%;
            }
        }
    }
    .link-box{
        text-align: left;
    }
}

.tekst-image-above {
    display: flex;
    flex-direction: column;

    .tekst-images{
        justify-content: center;
        margin-bottom: 50px;
    }
    .link-box{
        a{
            background: transparent!important;
        }
    }
}

.frontpage {
    .tekst-image-above {
        h1 {
            max-width: 525px;
            color: var(--color-orange);
        }
    }
}

.tekst-image-right {
    align-items: flex-start;
}

.tekst-image-below {
    display: flex;
    flex-direction: column-reverse;
}

.tekst-image-above .tekst-images, .tekst-image-below .tekst-images {
    display: flex;
    flex-direction: row;
}
.tekst-image-left .tekst-images, .tekst-image-right .tekst-images {
    display: flex;
    flex-direction: column;
}
.tekst-image-left .tekst-images, .tekst-image-right-center .tekst-images {
    display: flex;
    flex-direction: column;
}

.prosjekter__bilde {
    text-align: center;
}
