.arrangement-layout,
.nyhet-layout {
  margin-bottom: 50px;

  .arrangement-image,
  .nyhet-image {
    margin-bottom: 50px;
  }

  .intro {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .image-text {
    margin-bottom: 1.5rem;
    margin: 0.5rem 0 1rem 0;
    color: #504f4f;
    font-size: 14px;
  }

  .list {
    &__item {
      margin: 0.5rem 0;
    }

    &--no-style {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .meta-list {
    display: grid;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 20px 0;

    &__item {
      font-size: 18px;
      margin-bottom: 15px !important;
      display: flex;
      margin: 0 1.5rem 0.5rem 0;
      align-items: flex-start;

      &--separate {
        flex-basis: 100%;
        font-weight: 600;
        margin: 0 2rem 1rem 0;
        margin-top: 15px;
        margin-bottom: 0.5rem;
      }
    }

    &__icon {
      height: 1.4em;
      width: 1.2em;
      display: inline-block;
      margin-right: 0.5rem;
      padding-top: 1.125rem;

      &--calendar {
        background: url(../img/icons/calendar.svg);
        background-repeat: no-repeat;
        background-position: 0 2px;
        background-size: 100%;
      }

      &--clock {
        background: url(../img/icons/clock.svg);
        background-repeat: no-repeat;
        background-position: 0 5px;
        background-size: 95%;
      }

      &--map-pin {
        background: url(../img/icons/map-pin.svg);
        background-repeat: no-repeat;
        background-position: 0px 4px;
        background-size: 22px;
      }

      &--info-black {
        background: url(../img/icons/info_black.svg);
        background-repeat: no-repeat;
        width: 2.1em;
        height: 1.8em;
        background-position: 0;
        margin-top: -2px;
        background-size: contain;
      }
    }
  }

  .icon-with-text {
    display: flex;
    align-items: center;

    &__image {
      height: 2.5em;
      width: auto;
      margin-right: 0.75em;
    }

    &__text {
      display: block;
      font-size: 18px;
    }

    &__text-wrapper {
      display: inline-block;
    }

    &__subtext {
      margin-top: 1rem;
      font-size: 15px;
      line-height: 1.6px;
    }

    a {
      text-decoration: none;
      box-shadow: inset 0 -1px 0 #0667c6;
    }

    a:link,
    a:visited,
    a:active {
      color: #212121;
    }

    a:hover {
      color: #0667c6;
    }
  }

  .b-page-meta {
    color: #504f4f;
    font-size: 17px;
    margin: 2em 0;

    &__text {
      display: inline-block;
      border-right: 1px solid #504f4f;
      padding-right: 1em;
      margin-right: 1em;
    }
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
  }

  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    height: 100%;
    border: 0;
  }

  .opptak,
  .videostream {
    margin-bottom: 25px;
  }

  .alert {
    padding: 1em;
    border-left: 4px solid #504f4f;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &__content {
      margin-top: 0.25em;
      text-align: left;
      flex-grow: 1;
    }

    &--warning {
      border-color: #ffc900;
      background-color: #fef4ce;
    }

    &__icon {
      position: relative;
      width: 2em;
      height: 2em;
      border-radius: 100%;
      background-color: #212121;
      color: white;
      margin-right: 1em;
      flex-shrink: 0;

      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: '!';
      }

      &--warning {
        background-color: #d39000;
      }
    }
  }
}
