.htmlArea{
    width: 100%;
    h1{

    }
    p{

    }
}

audio {
    width: 100%;
    min-width: 100%;
}

video {
    width: 100%;
    min-width: 100%;
}