.site-footer {
	background-color: rgba(232, 232, 232, 1);
	padding: 30px 0 60px 0;
    @media ( max-width: 1025px ) {
        padding: 16px 0;
    }
}

.footer-widgets {
	flex-direction: column;
	margin: 0;
}

.footer-widget-1,
.footer-widget-2,
.footer-widget-3 {
	margin-right: 0;
}

.footer-widget {
	justify-content: space-between;
	margin: 0;

    & h4{
        font-size: 26px;
        font-weight: 700;
        line-height: 1.2;
        @media ( max-width: 1025px ) {
            font-size: 21px;
        }
    }

    & strong{
        font-size: 20px;
        font-weight: 700;
        @media ( max-width: 1025px ) {
            font-size: 16px;
        }
    }

	& p {
		color: var(--teft-theme-body-font-color);
		line-height: 1.2;
        font-size: 20px;
		text-align: left;
        margin-bottom: 18px;
        @media ( max-width: 1025px ) {
            font-size: 16px;
            margin-bottom: 16px;
        }
	}

	& a {
		color: var(--teft-theme-body-font-color);
		display: block;
		text-decoration: none;
	}

	& a:visited {
		color: var(--teft-theme-body-font-color);
		display: block;
		text-decoration: none;
	}

	& a:hover {
		color: var(--color-turquoise-dark);
	}

	& h4 {
		color: var(--teft-theme-body-font-color);
		margin: 0;
	}

	& img {
		width: 150px;
	}
}

@media ( min-width: 1024px ) {
	.site-footer {
		font-size: calc(20 / 20 * 1em); /* Keep footer at 16px base */
	}

	.footer-widgets {
		flex-direction: row;
		justify-content: space-between;
		margin: 0 auto;
	}

	.footer-widget {
		margin: 0.5em 0;
	}

	.footer-widget img {
		width: 230px;
	}

	.footer-widget-1 {
		margin: 0 calc(var(--teft-theme-gap) * 2) 0 0;
	}

	.footer-widget-2 {
		margin: 0 var(--teft-theme-gap);
	}

	.footer-widget-3 {
		margin: 0 0 0 var(--teft-theme-gap);
	}
}

.footer-widgets {
	display: flex;
}
