.link-box{
    text-align: center;
    a{
        display: inline-block;
        text-decoration: none;
        border-radius: 2px;
        border: 2px solid;
        font-size: 18px;
        line-height: 28px;
        min-width: 180px;
        color: var(--color-black);
        padding: 9px 27px;
    
        @media ( max-width: 1025px ) {
            font-size: 15px;
        }

        @media ( max-width: 600px ) {
            padding: 7px 15px;
        }

        &:hover{
            opacity: .7;
        }

        &.link-box-color{
            &--black{ border-color: var(--color-black); color:var(--color-black);}
            &--gray{ border-color: var(--color-gray); color:var(--color-gray);}
            &--gray-dark{ border-color: var(--color-gray-dark); color:var(--color-gray-dark);}
            &--gray-light{ border-color: var(--color-gray-light); color:var(--color-gray-light);}
            &--orange{ border-color: var(--color-orange); color:var(--color-orange);}
            &--purple{ border-color: var(--color-purple); color:var(--color-purple);}
            &--turquoise{ border-color: var(--color-turquoise); color:var(--color-turquoise);}
            &--turquoise-light{ border-color: var(--color-turquoise-light); color:var(--color-turquoise-light);}
            &--turquoise-dark{ border-color: var(--color-turquoise-dark); color:var(--color-turquoise-dark);}
            &--white{ border-color: var(--color-white); color:var(--color-white);}        
        }

        &.link-box-bg{
            &--black{ background-color: var(--color-black);}
            &--gray{ background-color: var(--color-gray);}
            &--gray-dark{ background-color: var(--color-gray-dark);}
            &--gray-light{ background-color: var(--color-gray-light);}
            &--orange{ background-color: var(--color-orange);}
            &--purple{ background-color: var(--color-purple);}
            &--turquoise{ background-color: var(--color-turquoise);}
            &--turquoise-light{ background-color: var(--color-turquoise-light);}
            &--turquoise-dark{ background-color: var(--color-turquoise-dark);}
            &--white{ background-color: var(--color-white);}
        }
    }

}