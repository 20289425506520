.carousel-desktop{
    margin-bottom: 50px;
    display: block;
    @media ( max-width: 700px ) {
        display: none;
    }
    .carousel-part{
        margin-bottom: 50px;
        padding-bottom: 35px;
        transition: all 300ms;
        height: auto;
        *{
            transition: all 300ms;
        }
    
        .title{
            max-width: 710px;
            margin: 0 auto 60px auto;
            font-size: var(--teft-typography-xl);
            font-weight: var(--avn-weight-light);
        }
    
        &__items{
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 30px;
            position: relative;
            padding: 0 70px;
    
            @media ( max-width: 700px ) {
                padding: 0 50px;
            }
            
            .carousel-item-desktop{
                width: 24%;
                @media ( max-width: 700px ) {
                    width: 100%;
                }
                img{
                    width: 100%;
                    max-width: 200px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    
        &__pagination{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 40px;
            column-gap: 10px;
            li{
                background: var(--color-orange);
                border: 2px solid var(--color-orange);
                height: 12px;
                width: 12px;
                border-radius: 12px;
                margin-bottom: 10px;
                cursor: pointer;
                &.active{
                    background: #ffffff;
                }
            }
        }
    
        .carousel-prev,
        .carousel-next{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            border: none;
            cursor: pointer;
    
            @media ( max-width: 700px ) {
             transform: translateY(-50%) scale(.6);
            }
    
            &:after{
                height: 100%;
                display: block;
            }
        }
    
        .carousel-prev{
            left: 0;
            &:after{
                content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC45OSIgaGVpZ2h0PSI1OS4xNTMiIHZpZXdCb3g9IjAgMCAzMC45OSA1OS4xNTMiPgogIDxnIGlkPSJHcm91cF85IiBkYXRhLW5hbWU9Ikdyb3VwIDkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuOTE0IDEuMDc2KSI+CiAgICA8cGF0aCBpZD0iTGluZV81IiBkYXRhLW5hbWU9IkxpbmUgNSIgZD0iTS41LjUsMjguNjYyLDI4LjY2MiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAyOCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2YzOTY2MiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxwYXRoIGlkPSJMaW5lXzVfQ29weSIgZGF0YS1uYW1lPSJMaW5lIDUgQ29weSIgZD0iTS41LjUsMjguNjYyLDI4LjY2MiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAyOSkgcm90YXRlKC05MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2YzOTY2MiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
            }
        }
    
        .carousel-next{
            right: 0;
            &:after{
                content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC45OTEiIGhlaWdodD0iNTkuMTUzIiB2aWV3Qm94PSIwIDAgMzAuOTkxIDU5LjE1MyI+CiAgPGcgaWQ9Ikdyb3VwXzUiIGRhdGEtbmFtZT0iR3JvdXAgNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wNzYgMS4wNzYpIj4KICAgIDxwYXRoIGlkPSJMaW5lXzVfQ29weV8yIiBkYXRhLW5hbWU9IkxpbmUgNSBDb3B5IDIiIGQ9Ik0uNS41LDI4LjY2MiwyOC42NjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI5IDI5KSByb3RhdGUoLTE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2YzOTY2MiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxwYXRoIGlkPSJMaW5lXzVfQ29weV8zIiBkYXRhLW5hbWU9IkxpbmUgNSBDb3B5IDMiIGQ9Ik0wLDAsMjguMTYyLDI4LjE2MiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjguNSAyOC41KSByb3RhdGUoOTApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmMzk2NjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K");            
            }
        }
    
    }
}
.carousel-mobile{
    display: none;
    @media ( max-width: 700px ) {
        display: block;
    }
    .carousel-part{
        margin-bottom: 50px;
        padding-bottom: 35px;
        transition: all 300ms;
        height: auto;
        *{
            transition: all 300ms;
        }

        .title{
            max-width: 710px;
            margin: 0 auto 60px auto;
            font-size: var(--teft-typography-xl);
            font-weight: var(--avn-weight-light);
        }
    
        &__items{
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            margin-left: -15px;
            margin-right: -15px;
            padding: 0 15px;
            .carousel-item-mobile{
                width: 60%;
                display: inline-block;
                vertical-align: middle;
                margin-right: 30px;
                &:last-child{
                    margin: 0;
                }
                .content{
                    display: flex;
                    align-items: center;
                    justify-content: center; 
                }

            }

        }
    
    }
}
