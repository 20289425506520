// // // Header // // //

.site-header--branding {
	margin: 13px auto 0;
	max-width: 150px;

    @media (max-width: 1025px){
 
    }

	& .custom-logo-link:hover {
		filter: brightness(1);
	}

	& .custom-logo-link img {
		width: auto;
		height: 95px;
		object-fit: contain;
        @media (max-width: 1025px){
            width: 100%;
        }
	}
}

.site-header--search {
	display: block;
}

.site-header--nav {
	display: none;
}

.site-header .site-container {
	position: relative;
}

.main-menu,
.main-navigation__is-visible .site-header--nav {
	display: block;
	margin-top: 0;
	width: 100%;
	z-index: 99999;
}

.site-header--nav-toggle {
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: inline-flex;
	left: 0;
	padding: 10px;
	position: absolute;
	top: 25px;
	z-index: 99999 !important;
    font-size: 20px;
    @media (max-width: 1025px){
        font-size: 16px;
    }    
}

.site-header--nav-toggle svg {
	height: 1.5em;
	margin-right: 0.5em;
	vertical-align: bottom;
	width: 1.5em;
    font-size: 100%; 
}

.main-menu {
	list-style: none;
	margin: 0;
	padding: 0;
}

.main-menu .sub-menu {
	list-style: none;
}

.main-menu a {
	align-items: center;
	color: var(--color-white);
	display: flex;
	font-size: 40px;
	height: 60px;
	letter-spacing: 0;
	line-height: 1;
	text-decoration: none;
	white-space: nowrap;
    @media (max-width: 1025px){
        font-size: 32px;
    }
}

.menu-item {
	border: none;

	&:first-of-type {
		border: none;
	}
}

/* Menu button */

.site-header--nav-toggle {
	align-items: center;
	background: none;
	color: var(--teft-theme-body-font-color);
	cursor: pointer;
	display: flex;
	fill: var(--teft-theme-body-font-color);
	flex-direction: row;
	height: 2em;
	margin: 0 -0.5em;
	order: 1;
	padding: 0 0.5em;
	z-index: 4;

	& svg {
		margin: 0;
		order: 0;
		width: 1.5em;
	}

	& rect {
		height: 2px;
		transform: 2s;
		transition: transform 0.2s;
		// width: 1.4em;
		x: 0;
        @media (max-width: 1025px){
            width: 1.06em; 
        }  
	}

	& .label {
		margin: 0 0 0 0.188em;
		order: 1;
	}

	&:focus {
		background: none;
	}

	&:hover {
		color: var(--teft-theme-primary-color);
		fill: var(--teft-theme-primary-color);
	}
}

.site-search__is-visible .site-header--nav-toggle {
	color: var(--color-white);
	fill: var(--color-white);
}

.site-search__is-visible .site-header--nav-toggle:hover {
	color: var(--teft-theme-primary-color);
	fill: var(--teft-theme-primary-color);
}

.main-navigation__is-visible {

	& .site-content,
	& .site-footer {

		/* Hide content and footer for visible menu on mobile. */
		display: none;
	}

	& .site-header--branding .custom-logo-link {
		display: none;
	}

	& .site-header {
		background: #494949;
		height: 100vh;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 100;
	}

	& .site-header--nav {
		left: -0.4em;
		margin-top: 4em;
		position: relative;
	}

	& .site-header--nav-toggle {
		color: var(--color-white);
		fill: var(--color-white);
		padding-left: 0;
		top: 25px;

		&:hover {
			color: var(--teft-theme-primary-color);
			fill: var(--teft-theme-primary-color);
		}
	}

	& .site-header--nav-toggle rect:nth-child(1) {
		transform: rotate(45deg);
		x: 26%;
		y: 2%;
	}

	& .site-header--nav-toggle rect:nth-child(2) {
		display: none;
	}

	& .site-header--nav-toggle rect:nth-child(3) {
		transform: rotate(-45deg);
		x: -44%;
		y: 60%;
	}

	& .site-header--nav-toggle label {
		color: var(--color-white);
	}

	& a {
		color: var(--color-white);
		white-space: normal;
		min-height: 60px;
		height: auto;
	}

	& a:hover {
		color: var(--teft-theme-primary-color);
	}

	& a:visited {
		color: #fff;
	}

	& .current-menu-item > a {
		color: var(--teft-theme-primary-color);
	}
}

@media ( min-width: 901px ) {
	.main-menu a {
		height: 60px;
		padding: 0.65625em 0;
	}
}

@media ( min-width: 1025px ) {
	.site-header--branding {
		max-width: inherit;
	}

	.site-header--nav-toggle {
		display: inline-flex;
	}

	.main-navigation__is-visible .site-header--nav {
		display: block;
	}

	.main-menu {
		display: block;
	}

	.main-menu a {
		height: 60px;
		padding: 0.65625em 0;
	}

	.main-navigation__is-visible .site-header--nav-toggle rect:nth-child(1) {
		transform: rotate(45deg);
		x: 16%;
		y: -4%;
	}
}

.site-header {
	padding: 0 var(--teft-theme-site-padding);
}

.site-header .site-container {
	align-items: center;
	display: flex;
	flex-flow: row wrap;
}

@media (--theme-desktop-menu) {
	.site-header .site-container {
		min-height: 70px;
	}
}

// // // Menu // // //
.site-header--nav-toggle {
	display: inline-flex;
}



// // // Search // // //
.site-header--search {
	position: relative;
	width: 100%;
}

.site-search {
	display: none;
	margin-top: 15px;
}

.main-navigation__is-visible .site-search {
	display: block;
}

.site-search .search-form {
	display: flex;
	margin-bottom: 0;
}

.site-search .search-form > label {
	flex-grow: 1;
}

.site-search .search-field {
	height: 40px;
	font-size: 20px;
}

.site-search .search-submit {
	margin-left: 10px;
    height: 48px;
    min-width: 93px;
    border-radius: 3px;
	font-size: 20px;
    border: none;
	cursor: pointer;
}

.site-header {
    .search-form{
        .screen-reader-text{
            display: none;
        }
    }
}

.site-header--search--toggle {
	background-color: transparent;
	border: none;
	display: none;
	margin-right: -10px;
	padding: 10px;
    font-size: 20px;
    @media (max-width: 1025px){
        font-size: 16px;
    } 
}

.site-search__icon {
	height: 1.125em;
	vertical-align: bottom;
	width: 1.125em;
    font-size: 100%;
}

@media (--theme-desktop-menu) {
	.site-header--search {
		width: auto;
	}

	.site-search {
		background-color: #fff;
		box-shadow: 0 1px 15px rgba(27, 31, 35, 0.15);
		margin-top: 0;
		padding: 16px;
		position: absolute;
		right: -10px;
		top: 100%;
	}

	.site-search .search-field {
		width: auto;
	}

	.main-navigation__is-visible .site-search {
		display: none;
	}

	.site-search__is-visible .site-search,
	.site-header--search--toggle {
		display: block;
	}
}


.main-navigation__is-visible .site-search {
	display: none;
}

.main-navigation__is-visible .site-header--search--toggle {
	display: inline-block;
}

.site-search__is-visible .site-search {
	display: block;
	margin-top: 0;
}

.site-header--search {
	display: block;
    position: absolute;
    right: 0;
    top: 22px;
    width: auto;
}

.site-header--search--toggle {
	cursor: pointer;
	display: flex;
	align-items: center;
	column-gap: 4px;
	position: relative;
	z-index: 99999;

	&:hover {
		color: var(--teft-theme-primary-color);
		fill: var(--teft-theme-primary-color);
	}
}

.site-search__is-visible .site-header--search--toggle:hover,
.main-navigation__is-visible .site-header--search--toggle:hover  {
	color: var(--teft-theme-primary-color);
	fill: var(--teft-theme-primary-color);
}

.site-search-close__icon {
	display: none;
}

.site-search__is-visible .site-search-close__icon {
	display: inline-block;
	height: 1.125em;
    vertical-align: bottom;
    width: 1.125em;
}

.site-search__is-visible .site-search__icon {
	display: none;
}

.site-header--search--toggle .label {
	display: inline-block;
}

.site-header--search--toggle .label-close {
	display: none;
}

.site-search__is-visible .site-header--search--toggle .label-close {
	display: inline-block;
}

.site-search__is-visible .label {
	display: none;
}

.site-search__is-visible .site-header--nav-toggle .label {
	display: inline-block;
}

.main-navigation__is-visible .site-header--search--toggle .label {
	color: var(--color-white);
	display: inline-block;
	margin-right: 3px;
}

.main-navigation__is-visible .site-header--search--toggle .label-close {
	display: none;
}

.main-navigation__is-visible .site-search__icon {
	color: var(--color-white);
	fill: var(--color-white);
}

.site-search__is-visible .site-header--search--toggle {
	color: var(--color-white);
	fill: var(--color-white);
}

.site-search {
	background-color: var(--color-black);
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 9999;
}

.search-form {
	justify-content: center;
	max-width: var(--teft-theme-site-width);
}

@media ( min-width: 200px ) {
	.search-form {
		margin: 100px 15px 10px;
	}

	.site-search {
		height: 200px;
	}
}

.search-results-list {
	border-bottom: 1px solid var(--color-gray-dark);
    padding: 15px 0 20px;

	h2 {
		font-size: var(--teft-typography-l);
		line-height: 35px;
	}
	h2 > a {
		border-bottom: 1px solid var(--color-turquoise);
		color: var(--color-turquoise-dark);
		text-decoration: none;
		-webkit-transition: all .2s;
		transition: all .2s;
		font-weight: var(--avn-weight-bold);
	}

	p {
		line-height: 24px;
    	margin: 10px 0 2px;
	}

	span {
		display: block;
		font-size: var(--teft-typography-sm);
		font-style: italic;
		line-height: 20px;
		margin-top: 6px;
	}
}

.search-form {
	margin: 150px auto 10px;
	display: flex;

	.search-submit {
		background-color: var(--color-orange);
		margin-left: 20px;
		border-radius: var(--teft-form-border-radius);
		height: var(--teft-form-input-height);
		min-width: 93px;
		opacity: 1;
		-webkit-transition: opacity .3s;
		transition: opacity .3s;
		border: none
	}

	input[type=search] {
		background-color: var(--color-white);
		border: 3px solid var(--color-orange);
		padding: 0 15px;
		height: var(--teft-form-input-height);
		border-radius: var(--teft-form-border-radius);
		width: 100%;
	}
}

@media ( min-width: 1024px ) {

	.search-form input[type=search] {
		min-width: 420px;
	}

	.search-result-total {
		display: flex;
		width: 100%;
		margin-left: 90px;
		padding-bottom: 40px;
	}

	.site-search {
		height: 400px;
	}
}

@media ( max-width: 201px ) {
	.search-form input[type=search] {
		min-width: auto;
	}
}

.site-search .search-field {
	height: 48px;
	width: 100%;
}

.site-search .search-form > label {
	max-width: 480px;
}

input[type=search] {
	background-color: var(--color-white);
	border: 3px solid var(--color-orange);
	padding: 0 15px;
}

input[type=submit] {
	background-color: var(--color-orange);
}
