html, body, * {
	font-family: "Calibri","Lucida Grande",arial,sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.4em;
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
	// text-rendering: optimizeLegibility;
	hyphens: none;                        
	-moz-hyphens: none !important;
	-ms-hyphens: none !important;
	-webkit-hyphens: none !important;
	word-wrap: break-word;
	@media ( max-width: 1023px ) {
		font-size: 16px;
    }
  }
