.c-transport-module{
    margin-bottom: 20px;
    .row {
        // margin-inline: -15px;
    }

    h2{
        font-size: var(--teft-typography-xxl);
        font-weight: var(--avn-weight-regular);
        line-height: 1.4em;
        margin-bottom: 20px;
        margin-inline: 15px;
    }
    .c-box{
        position: relative;
        // padding-bottom: 30px;

        @media ( max-width: 1025px ) {
            margin-bottom: 30px;
        }

        // &:after{
        //     content: '';
        //     width: calc(100% - 20px);
        //     height: 1px;
        //     position: absolute;
        //     bottom: 0;
        //     left: 10px;
        //     background: var(--color-gray-dark);
        //     @media ( max-width: 1025px ) {
        //         left: 15px;
        //         width: calc(100% - 30px);
        //     }
        // }

        &.col-md-3.col-6{
            &:after{
                display: none;
            }
        }

        &:hover{
            .c-box__text-container{
                .c-box__heading{
                    border-bottom: 1px solid transparent;
                }
            }
        }
        
        .c-transport-module__image{
            margin-bottom: 20px;
        }
        .c-box__text-container{
            a{
                text-decoration: none;
            }
            .c-box__heading{
                border-bottom: 1px solid var(--color-purple);
                color: var(--color-purple);
                display: inline;
                font-size: 26px;
                line-height: 35px;
                margin: 20px 0 0;
                -webkit-transition: border-bottom .3s;
                transition: border-bottom .3s;
                @media ( max-width: 1025px ) {
                    font-size: 21px;
                }
            }
        }
    }
    .link-box{
        a{
            color: #000000!important;
        }
    }
}
